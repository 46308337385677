.c-instagram {
  @apply bg-white flex flex-wrap;

  &__post {
    @apply inline-block w-1/2;

    font-size: 0;
    line-height: 0;
    max-width: 240px;
    max-height: 240px;

    img {
      @apply w-full h-full;
    }
    &:hover img {
      /* @apply opacity-90; */
      opacity: 0.9;
    }
  }
}

@screen md {
  .c-instagram {
    &__post {
      @apply w-1/3;
    }
  }
}
