.uniform__potty {
    position: absolute;
    left: -9999px;
}

.c-form {

  @apply .mb-12;

	input, textarea {
    @apply
      .text-black
      .px-1
      .py-1
      .my-2;

    border-radius: 0px;
    outline: none !important;
    border: 1px solid config('colors.aqua-haze');
    box-shadow: 0 0 0px config('colors.aqua-haze');

    &:hover, &:focus {
      outline: none !important;
      border: 1px solid config('colors.norway');
      box-shadow: 0 0 0px config('colors.norway');
    }

    &::placeholder {
      opacity: 1 !important;
      @apply .text-jaguar;
    }

    &[type="text"], &[type="email"] {
      min-width: 200px;
      max-width: 510px;
    }

    &[type="submit"] {
      @apply .cursor-pointer .mr-0 .bg-black .border-white .text-white .block .px-8 .py-4 .font-bold .text-2xl .mt-12;
      letter-spacing: 1px;
      &:hover {
        @apply .bg-white .border-white .text-black;
      }
    }
  }

  select {
    min-width: 200px;
    max-width: 510px;

    border-radius: 0px;
    outline: none !important;
    border: 1px solid config('colors.aqua-haze');
    box-shadow: 0 0 0px config('colors.aqua-haze');

    @apply my-2 bg-white;
    height: 24px;
  }

  label {
    min-width: 95px;
    @apply .inline-block;
  }

  &__checkbox, &__confirm {
    @apply .py-1;
  }
  /*
  input[type="checkbox"] {
    display:none;
	}
	input[type="checkbox"] + label {
		cursor: pointer;
	}
	input[type="checkbox"] + label span:first-of-type {
	  display: inline-block;
	  width: 20px;
    height: 20px;
	  vertical-align: middle;
	  background: url('/assets/images/checkbox.png') left center no-repeat;
	  cursor:pointer;
    margin-right: 10px;
	}
  input[type="checkbox"]:not(:checked):hover + label span:first-of-type {
	  background: url('/assets/images/checkbox.png') -20px center no-repeat;
	}
	input[type="checkbox"]:checked + label span:first-of-type {
	  background: url('/assets/images/checkbox.png') -40px center no-repeat;
	}
  */

	.ajax {
    &--progress {
      input[type='submit'], input[type='submit']:hover {
        cursor: progress;
        @apply .border-jaguar .bg-jaguar .text-white;
      }
    }

    &--success {
      input[type='submit'], input[type='submit']:hover {
        cursor: default;
        @apply .border-black .bg-black .text-white;
      }
    }

    &--error {
      input[type='submit'], input[type='submit']:hover {
        cursor: not-allowed;
        @apply .border-black .bg-white .text-black;
      }
    }
  }

  &#newsletter-optin {
    .c-form__checkbox:first-of-type,
    .c-form__unsubscribe {
      @apply .mt-4;
    }
  }

  &#newsletter-optout {

  }

  .ajax.ajax--success,
  .ajax.ajax--error,
	.ajax ~ &__message {
		display: none;
	}
	.ajax.ajax--success ~ &__message--success {
		display: block;
	}
	.ajax.ajax--error ~ &__message--error {
		display: block;
	}

  p + & {
    @apply .mt-12;
  }
}
