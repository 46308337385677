.c-logo {

  @apply bg-isabelline p-4 flex bg-right-bottom bg-no-repeat bg-contain;

  a {
    &, &:hover, &:active, &:visited {
      @apply text-black no-underline;
    }
  }

  &__home {
    @apply flex-shrink flex-no-grow inline-block h-10 overflow-hidden;
    img {
      @apply h-full w-auto;
      max-width: 204px;
    }
  }

  &__spacer {
    @apply flex-1 flex-grow;
  }

  &__social {
    @apply list-reset flex-no-shrink text-right;

    li {
      @apply inline-block;

      a {
        @apply inline-block w-10 h-10 p-2;
      }
    }
  }

  a {
    @apply text-black;
    &:hover {
      @apply text-norway;
    }
    svg, img {
      @apply fill-current stroke-current;
    }
  }

  &__toggle {
    @apply w-10 h-10 p-2 bg-center bg-no-repeat bg-contain ml-3;
    background-size: 24px 24px;

    &:not(.is-open) {
      background-image: url('/assets/images/toggle.svg');
    }
    &.is-open {
      background-image: url('/assets/images/toggle-open.svg');
    }
  }

   /* TODO: js */
  &__toggle.hover + &__main {
    @apply block;
  }
}

@screen md {
  .c-logo {

    background-image: url('/assets/images/flowers.svg');

    @apply p-4;

    &__home {
      @apply h-auto p-12;
      img {
        @apply h-auto;
      }
    }

    &__social {
      @apply mr-2;

      li a {
        @apply w-8 h-8 p-2;
      }
    }

    &__toggle {
      @apply hidden;
    }
  }
}

@screen 2xl {
  .c-logo {
    &__home {
      img {
        @apply ml-6;
      }
    }
  }
}
