.c-carousel {

  &:not(.single) {
    @apply
      .mb-12 text-center;
  }

  &__cell {
    &, img {
      width: 100%;
    }
    &, figure, img {
      font-size: 0;
      line-height: 0;
    }
  }
  &.p-width .c-carousel__cell {
    figure {
      text-align: center;
    }
    figure, img {
      width: auto;
    }
  }

  &__caption {
    @apply
      .mt-1
      .text-center;
  }

  &.has-dots-inside .flickity-page-dots {
    @apply
      .mb-10
      .pin-x
      .pin-b;
  }

  .flickity-prev-next-button.next {
    right: 2rem;
  }

  .flickity-prev-next-button.previous {
    left: 2rem;
  }
}
