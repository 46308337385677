.c-cookie {

  @apply bg-norway text-white;

  .container {
    @apply p-4 flex items-center;
  }

  &__message {
    @apply flex-grow pr-8;
    p {
      max-width: none;
    }
    a, a:hover, a:visited, a:active, a:focus {
      @apply text-aqua-haze;
    }
  }

  &__button {
    @apply border-2 border-white px-4 py-2 font-bold cursor-pointer;

    &:hover {
      @apply bg-white text-norway;
    }
  }
}

@screen md {
  .c-cookie {
    &__message p {
      @apply text-right;
    }
    &__button {
      @apply mr-3;
    }
  }
}
