.c-frame {

  &__image {
    @apply p-4;
    img {
      @apply w-full h-auto;
    }
  }

  &__link {
    @apply w-full flex flex-wrap;

    &, &:hover, &:active, &:visited {
      @apply text-black no-underline;
    }

    > * {
      @apply w-full;
    }
  }

  &__text {

    @apply p-8;

    h3, h2 {
      @apply block w-full text-center;
    }

    h2 + h3 {
      @apply mt-1;
    }

    h3 + p, h2 + p {
      @apply mt-12;
    }
  }

}

@screen md {
  .c-frame {

    &__image {
      padding: 3.5rem;
    }

    &__link {
      @apply w-full flex flex-wrap;

      > * {
        @apply w-1/2;
      }
    }

    &:nth-child(even) &__link {
      @apply flex-row-reverse;
    }

    &:nth-child(even) &__text > div {
      @apply p-12;
      padding-left: 120px;
    }
    &:nth-child(odd) &__text > div {
      @apply p-12;
      padding-right: 120px;
    }

    &__text {
      @apply flex flex-wrap content-center p-12;
    }
  }

}
