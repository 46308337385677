body {
  @apply
    font-sans
    text-sm
    text-black
    font-normal
    leading-normal;x

  h1, h2, h3, h4, h5 {
    @apply font-bold text-sm leading-tight;
  }

  h2 {
    @apply font-serif font-normal italic text-3xl;
  }

  h3 {
    @apply uppercase;
    letter-spacing: 1px;
  }

  /*
  h1, h2, h3, h4, h5 {
    @apply font-caps font-bold text-2xl leading-hair uppercase;
  }
  h1, h2 {
    max-width: 40ch;
    letter-spacing: 15px;
  }
  h3, h4, h5 {
    @apply inline-block;
    max-width: 45ch;
    letter-spacing: 1px;
  }
  */

  button {
    @apply text-base font-normal;
  }

  * + p {
    @apply
      pt-0;
  }

  /*
  h2, h3 {
    @apply pb-2 mb-2;
  }

  h2 {
    &:not(:first-of-type) {
      @apply mt-12;
    }
  }

  * + h3 {
    @apply pt-6;
  }

  h3 {
    @apply mt-12;
    text-decoration: underline;
  }
  */

  p, blockquote {
    max-width: 100ch;
    word-break: keep-all;

    @apply text-justify;

    & + p {
      @apply
        pt-6;
    }
  }

  strong, b {
    @apply font-bold;
  }

  i {
    @apply italic;
  }

  ol, ul {
    @apply list-reset pt-0;
    li {
      max-width: 65ch;
    }
  }

  a {
    &, &:hover, &:active, &:focus, &:visited {
      @apply text-norway no-underline;
    }

    &:hover, &:focus {
      @apply underline;
    }

    &.silent:hover, &.silent:focus {
      @apply no-underline;
    }

  }

  a.button {
    @apply
      inline-block
      whitespace-no-wrap
      border-aqua-haze
      border-2
      px-3
      py-2;

    &, &:hover, &:active, &:visited, &:focus {
      @apply
        font-normal
        text-aqua-haze
        no-underline;
    }

    &:hover {
      @apply
        text-white
        bg-aqua-haze
        font-normal;
    }

    &.wide {
      @apply
        w-full
        text-center;
    }
  }
}


@screen md {
  body {

    h3 {

    }

    h2 {
      @apply text-4xl;
    }

    /*
    h1, h2 {
      @apply font-bold text-8xl leading-hair;
      letter-spacing: 3px;
    }
    h3, h4, h5 {
      @apply font-bold text-5xl leading-hair;
      letter-spacing: 2px;
    }
    p, button {
      @apply text-2xl leading-normal;
    }
    */
  }
}
