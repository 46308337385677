.c-columns {

  @apply relative bg-center bg-no-repeat bg-cover p-8;
  min-height: 30vh;

  &__wrapper {
    @apply w-full flex justify-center;
  }

  &__link {
    @apply block w-full;

    &, &:hover, &:active, &:visited {
      @apply text-black no-underline;
    }
  }

  &__text {
    @apply w-full text-center pb-12;

    h2 {
      @apply inline-block;
      max-width: 21ch;
    }
    h2 + h3 {
      @apply mt-3;
    }
  }

  &__textlong {
    column-gap: config('padding.12');
  }
}

@screen md {
  .c-columns {

    &__link {
      @apply p-8;
    }

    &__textlong {
      max-width: 120ch;
      column-count: 2;
    }
  }
}
