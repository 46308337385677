.c-text {

  @apply bg-center bg-no-repeat bg-cover;

  &__text {
    @apply w-full;
    overflow: hidden;

    em {
      @apply font-sans text-2xl italic block py-12;
    }

    blockquote {
      @apply bg-isabelline p-8 mt-6;

      h2 + p {
        @apply mt-2;
      }
    }

    table {
      @apply w-full mt-12;

      & + * {
        @apply mt-12;
      }

      width: calc( 100% - 20vw );
      border-collapse: collapse;
      white-space: nowrap;

      border-collapse: separate;
      border-spacing: 2rem .5rem;

      th {
        @apply font-normal;
      }
      th, td {
        @apply text-left py-1;
      }

      th:first-child:nth-last-child(1), th:first-child:nth-last-child(1) ~ th,
      td:first-child:nth-last-child(1), td:first-child:nth-last-child(1) ~ td { width: 100%; }
      th:first-child:nth-last-child(2), th:first-child:nth-last-child(2) ~ th,
      td:first-child:nth-last-child(2), td:first-child:nth-last-child(2) ~ td { width: 50%; }
      th:first-child:nth-last-child(3), th:first-child:nth-last-child(3) ~ th,
      td:first-child:nth-last-child(3), td:first-child:nth-last-child(3) ~ td { width: 33.3333%; }
      th:first-child:nth-last-child(4), th:first-child:nth-last-child(4) ~ th,
      td:first-child:nth-last-child(4), td:first-child:nth-last-child(4) ~ td { width: 25%; }
      th:first-child:nth-last-child(5), th:first-child:nth-last-child(5) ~ th,
      td:first-child:nth-last-child(5), td:first-child:nth-last-child(5) ~ td { width: 20%; }
      th:first-child:nth-last-child(6), th:first-child:nth-last-child(6) ~ th,
      td:first-child:nth-last-child(6), td:first-child:nth-last-child(6) ~ td { width: 16.6666%; }

      a, a:hover, a:visited, a:active, a:focus {
        @apply font-bold text-black;
      }
      a:hover {
        @apply underline;
      }
    }
  }

  &:not(.text-center).has-padding .c-text__text {
    @apply m-8;
  }

  &.text-center {
    .c-text__text {

      @apply flex flex-wrap justify-center items-center;

      > div {
        *:not(figure):not(img):not(table):not(tr):not(td) {
          @apply w-full;
        }
        p {
          @apply text-center;
        }
      }
    }
    &.has-padding {
      .c-text__text {
        @apply m-8;
      }
    }
  }

  &__link {
    &, &:hover, &:active, &:visited {
      @apply text-black no-underline;
    }
  }

  &.has-columns .c-text__text {
    column-gap: config('padding.12');
  }
}

@media only screen and (max-width: 576px) {

  .c-text {
    table, thead, tbody, th, td, tr {
      display: block;
      width: 100%!important;
    }
  }
}

@screen md {
  .c-text:not(.text-center).has-padding .c-text__text {
    @apply m-12;
  }
}

@screen lg {
  .c-text.has-columns .c-text__text {
    max-width: none;
    column-count: 2;
  }
}
