.c-body {

  @apply bg-white-smoke;

  ::selection, mark {
    @apply
      .text-black
      .bg-aqua-haze;
  }

  padding-top: 72px; /* c-fixed */
}

@screen md {
  .c-body {
    padding-top: 0;
  }
}
