.c-header {

  @apply flex flex-wrap;

  &__bg {
    @apply w-full bg-center bg-no-repeat bg-cover;
    min-height: 300px;
  }

  &__text {
    @apply w-full flex flex-wrap justify-center p-8;

    margin-top: -2rem;

    div {
      @apply bg-white -mt-8 pt-4 text-center;
      padding-right: 5rem;
      padding-left: 5rem;
    }

    h3 {
      @apply pt-2 pb-4;
    }

    div > * {
      @apply w-full text-center;
    }

    blockquote {
      @apply bg-isabelline p-4 mt-6;
    }
  }

}

@screen md {
  .c-header {
    &__bg {
      min-height: 430px;
    }
    &__text {
      @apply p-12;
      margin-top: -3rem;
    }
  }
}
