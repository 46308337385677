.c-nav-top {

  @apply hidden;
  &.is-open {
    @apply flex;
  }

  min-height: config('padding.12');
  z-index: 1000;

  &__main {
    @apply w-full p-4 list-reset bg-white p-4;

    li {
      @apply pb-1;
    }
  }

  &__title {
    /* height: 100%; */
  }

  &__dropdown {
    @apply list-reset;

    li {
      @apply pl-4 py-1;
    }
  }

  &__title, a {
    @apply uppercase font-bold;
    &, &:hover, &:active, &:visited {
      @apply text-black no-underline;
    }
  }

  a:hover {
    @apply text-aqua-haze;
  }

}

@screen md {
  .c-nav-top {

    &:not(.is-open) {
      @apply block;
    }

    &.is-open {
      .c-nav-top__dropdown {
        @apply block;
      }
    }

    @apply block;

    &__main {
      @apply p-0 relative flex flex-wrap justify-around border-isabelline border-b;

      > li {
        @apply pb-0;
      }

      > li > div {
        @apply p-4;
      }
    }
    &__dropdown {
      @apply absolute list-reset hidden bg-white px-4 border-isabelline border-r border-l border-b;
      z-index: 1001;
      width: auto;
      top: 100%;
      top: calc(100% - 1px);
      box-shadow: 0px 2px 2px rgba(0,0,0,.125);
      min-width: 200px;
      > li {
        @apply pb-4;
      }
    }

    &__main {
      li:hover ul {
        @apply block;
      }
    }

    &__dropdown {
      @apply pt-2;

      li {
        @apply pl-0;
      }
    }
  }
}
