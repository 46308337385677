.c-newsletter {
  @apply bg-center bg-no-repeat bg-cover p-12 bg-isabelline;
  background-image: url('/assets/images/c-newsletter-bg.png');

  &__text {
    @apply w-full text-center;

    h2 {
      @apply mb-8;
    }
  }

  .c-form {
    @apply mb-0;
    label {
      @apply .text-left .pr-6;
    }

    .mc-field-group {
      @apply text-left;
    }

    .gdpr-mergeRow {
      @apply text-xs .text-left italic;
      margin-top: 2px;
      font-size: 10px;

      label:not(.checkbox):not(.subfield) {
        @apply font-bold text-left;
      }
      fieldset.gdprRequired {
        @apply text-sm roman text-left py-2;
        span {
          @apply pl-2;
        }
      }
      a {
        &, &:active, &:visited, &:focus, &:hover {
          @apply .text-black .underline;
        }
      }
    }

    .clear {
      @apply .text-left ml-4 .w-full;
    }

    input[type=submit] {
      &, &:hover {
        @apply inline-block m-0 bg-transparent border-0 text-sm border-b-4 p-0 pb-2 uppercase pt-4 font-bold border-norway text-black;
        margin-top: 3px;
        margin-left: 3px;
      }
      &:hover {
        @apply text-norway;
      }
    }
  }
}
