.c-nav-bottom {

  @apply px-8 py-4 bg-white;

  ul {
    @apply list-reset flex flex-wrap justify-center;

    li {
      @apply inline-block w-full text-center;
      a {
        &, &:hover, &:active, &:visited {
          @apply text-norway no-underline block;
          line-height: 42px; /* touchable */
        }
      }
    }
  }
}

@screen md {
  .c-nav-bottom {
    @apply text-right;
    ul {
      @apply flex-no-wrap justify-end;

      li {
        @apply w-auto;
        a {
          &, &:hover, &:active, &:visited {
            line-height: inherit;
          }
        }
      }

      li:not(:first-of-type) {
        @apply pl-6;
      }

      li:not(:last-of-type) {
        @apply border-aqua-haze border-r-2 pr-6;
      }
    }
  }
}
