.c-readmore {
  @apply w-full text-center uppercase pt-6 font-bold;
  letter-spacing: 1px; /* like h3 */

  span {
    @apply border-norway border-b-4 inline-block pb-1 mb-8;
  }
}

a.c-readmore {
  @apply block;

  &, &:active, &:visited, &:focus {
    @apply text-black;
  }
  &:hover {
    @apply text-norway;
  }

  span {
    width: auto !important;
  }
}
