.c-hero {

  @apply relative bg-center bg-no-repeat bg-cover;
  min-height: 300px;

  &__link {
    @apply block w-full;
    &, &:hover, &:active, &:visited {
      @apply text-black no-underline;
    }
  }

  &__wrapper {
    @apply absolute pin-x pin-y m-4;
  }

  &__text {
    @apply flex h-full items-center justify-center;

    .c-hero__board {
      @apply bg-white px-8 pt-8 text-center ;
      /* min-width: 40vw; */

      h3 {
        @apply text-center mb-4;
      }

      h2 {
        @apply inline-block;
        max-width: 24ch;
      }

      p {
        text-align: center;
      }
    }
  }

  &__link:hover &__board {

  }
}

@screen md {
  .c-hero {
    min-height: 430px;

    &__text .c-hero__board {
      @apply pt-12;
      padding-left: 120px;
      padding-right: 120px;
    }
  }
}
