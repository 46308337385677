.c-blog {

  @apply w-full flex flex-wrap -mx-2 p-8;

  header {
    @apply w-full text-center p-8 mb-12;
    p {
      @apply w-full text-center;
      max-width: none;
    }
  }

  .c-cards {
    @apply px-2;
  }

}

@screen md {

  .c-blog {

    @apply p-12;

    header {

    }
  }

}
