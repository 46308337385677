.c-cards {

  @apply relative flex-wrap content-start;

  &, &:hover, &:active, &:visited {
    @apply text-black no-underline;
  }

  img {
    @apply w-full h-full;
  }

  &__image {
    @apply w-full;
    font-size: 0;
    line-height: 0;
  }

  time {
    @apply hidden;
  }

  > div {
    @apply text-center w-full -mt-8;
    z-index: 40;
    h3 {
      @apply inline-block bg-white pt-4 pb-4 px-12;
    }
  }

  h2 {
    @apply block w-full text-center px-4;
  }

  h2 + &__excerpt, h3 + &__excerpt, time + &__excerpt {
    @apply mt-4;
  }

  /* regular version */
  &:not(.md\:w-1\/5) {

    .c-cards__image {
      z-index: 30;
    }

    &:hover .c-cards__image img {
      /* @apply opacity-90; */
    }
  }

  &.md\:w-1\/5 {
    .c-cards__excerpt > p {
      @apply px-8;
    }
  }
}

.c-cards + .c-cards {
  @apply mt-12;
}

@screen md {
  .c-cards + .c-cards {
    @apply mt-0;
  }

  .c-cards {
    h2 + &__excerpt, h3 + &__excerpt, time + &__excerpt {
      @apply px-6;
    }
  }

  .c-cards.md\:w-1\/3 {
    @apply mb-12;
  }

  /* hover version */
  .c-cards.md\:w-1\/5 {

    > div {
      @apply mt-0;
    }

    h3, h2 {
      @apply hidden;
    }
    .c-cards__excerpt {
      @apply absolute hidden pin-x pin-y bg-aqua-haze;

      > p {
        @apply px-12 pt-8 text-center;
      }
    }
    &:hover .c-cards__excerpt {
      @apply absolute flex flex-wrap items-center justify-center m-0 p-0;
    }
    .c-readmore {
      @apply hidden;
    }
    .c-readmore--find-shop {
      @apply block;
    }
  }
}
