.c-fixed {
  @apply fixed w-full;
  top: 0;
  left: 0;
  z-index: 1000;
  box-shadow: 0px 2px 0px rgba(0,0,0,.062);

  > * {
    @apply w-full;
  }
}

@screen md {
  .c-fixed {
    @apply static;
  }
}
